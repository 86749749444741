<template>
  <div class="follow-update-container">
    <div class="head">
      <div class="left">
        <img
          @click="goAuther(every)"
          v-lazy="$global.imgUrl + every.createUserImage"
          alt=""
        />
        <div class="info">
          <div class="name" @click="goAuther(every)">
            {{ every.createUserName }}
          </div>
          <div class="update">回复了一个讨论</div>
        </div>
      </div>
      <div class="right">更新了回复</div>
    </div>
    <div class="title" @click="goDetail">{{ every.discussTitle }}</div>
    <div class="content">
      {{ every.content }}
    </div>
    <div class="createDate">{{ every.auditDate }}</div>
  </div>
</template>

<script>
export default {
  props: {
    every: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    goAuther(v) {
      let url = this.$router.resolve({
        path: "/autherdetail",
        query: { id: v.createUserId },
      });
      window.open(url.href, "_blank");
    },
    goDetail() {
      let url = this.$router.resolve({
        path: "/discussdetails",
        query: {
          id: this.every.discussId,
        },
      });
      window.open(url.href, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
.follow-update-container {
  margin-top: 40px;
  width: 1040px;
  height: 250px;
  background: #ffffff;
  border: 1px solid #999999;
  border-radius: 12px;
  padding: 30px;
  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    .left {
      display: flex;
      img {
        width: 60px;
        height: 60px;
        margin-right: 15px;
        border-radius: 50%;
        cursor: pointer;
      }
      .info {
        .name {
          font-size: 22px;
          color: #444444;
          width: 200px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          cursor: pointer;
          span {
            margin-right: 6px;
          }
        }
        .update {
          font-size: 16px;
          color: #999999;
        }
      }
    }
    .right {
      span {
        font-size: 16px;
        color: #00a4ff;
        cursor: pointer;
        width: 400px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        vertical-align: middle;
        display: inline-block;
        text-align: right;
      }
    }
  }
  .title {
    margin-top: 19px;
    font-size: 24px;
    color: #00a4ff;
    cursor: pointer;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .content {
    margin-top: 18px;
    font-size: 16px;
    color: #333333;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .createDate {
    font-size: 14px;
    color: #999999;
    margin-top: 25px;
  }
}
</style>
