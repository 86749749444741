<template>
  <div class="follow-circle-container">
    <div class="container">
      <div v-for="(item, i) in allItemList" :key="i">
        <FollowItem v-if="item.bjType === 3" :every="item"></FollowItem>
        <FollowUpdate v-if="item.bjType === 2" :every="item"></FollowUpdate>
        <FollowUpdate1 v-if="item.bjType === 1" :every="item"></FollowUpdate1>
        <FollowUpdate2 v-if="item.bjType === 4" :every="item"></FollowUpdate2>
      </div>
      <div class="no-results" v-if="total === 0 && !isLoding">
        <img :src="require('@/assets/qietu/article.png')" alt="">
        <p>暂无关注</p>
      </div>
      <p v-show="isLoding" style="text-align: center;color: rgb(105, 98, 98);">加载中....</p>
    </div>
    <Side :sideList="sideList" title="关注学者"></Side>
  </div>
</template>

<script>
import Side from "../components/Side.vue";
import FollowItem from "../components/FollowItem.vue";
import FollowUpdate from "../components/FollowUpdate.vue";
import FollowUpdate1 from "../components/FollowUpdate1.vue";
import FollowUpdate2 from "../components/FollowUpdate2.vue";
export default {
  components: {
    FollowItem,
    Side,
    FollowUpdate,
    FollowUpdate1,
    FollowUpdate2,
  },
  data() {
    return {
      isLoding: false, // 加载
      pageNum: 1, // 当前页数
      total: 0, // 推荐总数
      sideList: [],
      allItemList: [],
    };
  },
  created() {
    this.getFollowAllList({
      pageNum: 1,
      pageSize: 10,
    });
    this.attentionList({
      // pageNum: 1,
      // pageSize: 10,
    });
  },
  methods: {
    // 监听滚动条
    handleScroll() {
      const homeDoc = document.getElementsByClassName('home-container')
      if ((document.documentElement.scrollTop+document.body.offsetHeight) == homeDoc[0].offsetHeight ) {
        if (this.total / 10 > this.pageNum && !this.isLoding) {
          this.getFollowAllList({
            pageNum: this.pageNum + 1,
            pageSize: 10,
          });
        }
      }
    },
    async getFollowAllList(data) {
      this.isLoding = true
      const resp = await this.$apis.circleServe.getFollowAllList(data);
      if (resp.data) {
        this.allItemList.push(...resp.data.list);
        this.pageNum = +resp.data.pageNum  
        this.total = +resp.data.total
      }
      this.isLoding = false
      window.addEventListener('scroll',this.handleScroll) // 给滚动条添加事件
    },
    async attentionList(data) {
      const resp = await this.$apis.circleServe.attentionList(data);
      this.sideList = resp.data.list;
    },
  },
};
</script>

<style lang="scss" scoped>
.follow-circle-container {
  display: flex;
  align-items: flex-start;
  .container {
    width: 1040px;
    margin-right: 40px;
    .no-results {
      width: 483px;
      margin: 20px auto 0;
      img {
        width: 100%;
      }
      p {
        text-align: center;
        font-size: 16px;
        color: #999999;
      }
    }
  }
}
</style>
