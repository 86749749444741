<template>
  <div class="follow-item-container">
    <div class="head">
      <div class="left">
        {{ every.discussTitle }}
      </div>
      <div class="right">
        <div>
          <span class="createName" @click="goDetail2">{{
            every.createUserName
          }}</span
          ><span> - 发布了讨论 - </span>
          <span class="project" @click="goDetail1">{{
            every.circleTitle
          }}</span>
        </div>
        <Button
          type="primary"
          shape="circle"
          class="btn"
          @click="onFocusPro(every, every.discussId)"
          v-throttleClick="2000"
          >关注问题</Button
        >
      </div>
    </div>
    <div class="main">
      {{ every.content }}
    </div>
    <div class="bottom">
      <div>
        <Button class="all-btn" shape="circle" @click="goDetail"
          >全部回复<Icon type="ios-arrow-round-forward"
        /></Button>
      </div>
      <div class="createDate">{{ every.auditDate }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    every: {
      type: Object,
      required: true,
    },
  },
  methods: {
    onFocusPro(every, id) {
      console.log(every, id);
    },
    goDetail() {
      let url = this.$router.resolve({
        path: "/discussdetails",
        query: {
          id: this.every.discussId,
        },
      });
      window.open(url.href, "_blank");
    },
    goDetail1() {
      let url = this.$router.resolve({
        path: "/circledetails",
        query: {
          id: this.every.circleId,
        },
      });
      window.open(url.href, "_blank");
    },
    goDetail2() {
      let url = this.$router.resolve({
        path: "/autherdetail",
        query: {
          id: this.every.createUserId,
        },
      });
      window.open(url.href, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
.follow-item-container {
  margin-top: 30px;
  width: 1040px;
  // height: 474px;
  background: #ffffff;
  border: 1px dotted #999999;
  border-radius: 12px;
  padding: 30px;
  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    .left {
      font-size: 24px;
      color: #333333;
      width: 400 px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .right {
      display: flex;
      align-items: center;
      div {
        margin-left: 30px;
        font-size: 15px;
        color: #999999;
        display: flex;
        align-items: center;
        text-align: center;
        span {
          width: 100px;
          display: inline-block;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .project,
        .createName {
          color: #00a4ff;
          cursor: pointer;
        }
      }
      .btn {
        width: 120px;
        height: 38px;
        margin-left: 26px;
      }
    }
  }
  .main {
    width: 980px;
    // height: 267px;
    margin: 30px 0;
    overflow: auto;
    .title {
      font-size: 24px;
      color: #00a4ff;
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .content {
      margin-top: 18px;
      font-size: 16px;
      color: #333333;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      text-overflow: ellipsis;
    }
  }
  .bottom {
    .all-btn {
      width: 120px;
      height: 38px;
      border: 1px solid #00a4ff;
      color: #00a4ff;
    }
    .createDate {
      font-size: 14px;
      color: #999999;
      margin-top: 25px;
    }
  }
}
</style>
