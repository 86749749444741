<template>
  <div class="side-container" v-if="title === '热门学者'">
    <h2 class="title">{{ title }}</h2>
    <ul class="auther">
      <li v-for="(v, i) in sideList" :key="i">
        <div class="userBox" v-if="v.userInfo">
          <img
            v-if="v.userInfo.headImg"
            :src="$global.imgUrl + v.userInfo.headImg"
            alt=""
          />
          <img v-else :src="require('@/assets/imgs/head_bg.png')" />
          <div class="info" >
            <div class="name" @click="goAuther(v)">{{ v.userInfo.realName }}</div>
            <div class="school">{{ v.userInfo.orgName }}</div>
          </div>
        </div>
      </li>
    </ul>
  </div>
  <div class="side-container" v-else-if="title === '热门圈子'">
    <h2 class="title">{{ title }}</h2>
    <ul class="circle">
      <li v-for="(v, i) in sideList" :key="i" @click="goCircle(v)">
        <div class="subjects">{{ v.title }}</div>
        <div class="message">
          {{ v.scholarNum }}位学者加入，{{ v.postNum }}个帖子
        </div>
      </li>
    </ul>
  </div>
  <div class="side-container" v-else-if="title === '关注学者'">
    <h2 class="title">{{ title }}</h2>
    <ul class="auther">
      <li v-for="(v, i) in sideList" :key="i">
        <div class="userBox" v-if="v.userInfo">
          <img
            v-if="v.userInfo.headPhoto"
            :src="$global.imgUrl + v.userInfo.headPhoto"
            alt=""
          />
          <img v-else :src="require('@/assets/imgs/head_bg.png')" />
          <div class="info">
            <div class="name" @click="goAuther(v.userInfo)" v-if="v.userInfo">
              {{ v.userInfo.realname }}
            </div>
            <div class="school">{{ v.userInfo.unit }}</div>
          </div>
        </div>
      </li>
    </ul>
  </div>
  <div class="classification-container" v-else-if="title === '分类'">
    <h2 class="title">{{ title }}</h2>
    <ul class="subject">
      <li
        v-for="(v, i) in sideList"
        :key="i"
        :class="{ active: i === index }"
        @click="handleHonour(v, i)"
      >
        <span>{{ v.name }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    sideList: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      index: -1,
    };
  },
  methods: {
    goCircle(v) {
      let url = this.$router.resolve({
        path: "/circledetails",
        query: { id: v.id },
      });
      window.open(url.href, "_blank");
    },
    goAuther(v) {
      let url = this.$router.resolve({
        path: "/autherdetail",
        query: { id: v.userId },
      });
      window.open(url.href, "_blank");
    },
    handleHonour(item, i) {
      this.index = i;
      let element = this.$refs.sideLi[this.index];
      if (element.classList.contains("active")) {
        element.classList.remove("active");
        this.$emit("requestAll");
      } else {
        element.classList.add("active");
        this.$emit("requestClick", item);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.side-container {
  margin-top: -30px;
  width: 360px;
  min-height: 544px;
  background: #ffffff;
  border: 1px dotted #999999;
  border-radius: 6px;
  position: relative;
  padding: 38px 0 0px 30px;
  overflow: hidden;
  &::after {
    position: absolute;
    left: -1px;
    top: -1px;
    content: "";
    display: block;
    width: 100%;
    height: 4px;
    background-image: url("~@/assets/imgs/squre.png");
    background-size: 100% 100%;
  }
  .title {
    font-size: 22px;
    font-weight: 400;
    color: #00a4ff;
    margin-bottom: 5px;
  }
  .auther {
    li {
      margin: 30px 0;
      .userBox {
        display: flex;
        align-items: center;
        img {
          width: 60px;
          height: 60px;
          margin-right: 15px;
          border-radius: 50%;
        }
        .info {
          .name {
            font-size: 18px;
            color: #00a4ff;
            cursor: pointer;
            width: 200px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
          .school {
            width: 220px;
            font-size: 14px;
            color: #999999;
            margin-top: 8px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
      }
    }
  }
  .circle {
    padding-right: 30px;
    li {
      margin: 30px 0;
      cursor: pointer;
    }
    .subject {
      font-size: 18px;
      color: #333333;
    }
    .subjects {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 18px;
      color: #333333;
    }
    .message {
      font-size: 14px;
      color: #999999;
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
}
.classification-container {
  width: 260px;
  height: 466px;
  background: #ffffff;
  border: 1px dotted #999999;
  border-radius: 6px;
  position: relative;
  overflow: hidden;
  &::after {
    position: absolute;
    left: -1px;
    top: -1px;
    content: "";
    display: block;
    width: 100%;
    height: 4px;
    background-image: url("~@/assets/imgs/squre.png");
    background-size: 100% 100%;
  }
  .title {
    font-size: 22px;
    font-weight: 400;
    color: #00a4ff;
    margin-bottom: 5px;
  }
  ul {
    height: 298px;
    overflow: hidden;
    margin-bottom: 30px;
    li {
      height: 41px;
      line-height: 41px;
      display: flex;
      justify-content: space-between;
      padding: 0 28px;
      cursor: pointer;
      &.active {
        background-color: #00a4ff;
        color: #fff;
      }
    }
  }
}
</style>
